import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// BG
import HeroBg from "assets/images/regional-pcr-testing/hero.jpg"

// Components
import Container from "components/container"
import { ExternalButtonLink } from "components/buttons"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  max-width: 1200px;
  flex-direction: inherit;
  padding: 0;
  margin: 0 auto;

  ${breakpoint.small`
    flex-direction: row;
  `}
`

const StyledHero = styled.header`
  display: flex;
  flex-wrap: wrap;
  border-bottom-width: 0;
  overflow: hidden;
  background: url(${HeroBg});
  background-size: cover;
  background-color: ${colors.purpleDark};
  background-position-x: center!important;
  background-position-y: center!important;
  background-repeat: no-repeat;
  flex-direction: column-reverse; 
  min-height: 600px;
    
  ${breakpoint.small`
    height: 600px;
  `}

  ${breakpoint.medium`
    height: 750px;
  `}

  ${breakpoint.large`
    height: 900px;
  `}
  
  ${breakpoint.extraLarge`
	background-size: contain;
  `}


  .block--text {
    width: 100%!important;
    max-width: 1412px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: #FFF7;
    text-align: center;
    border-top: ${colors.grayMedium} 1px solid;

	  ${breakpoint.small`
      flex-wrap: nowrap;
      flex-direction: row;
      text-align: left;
	  `}
    
    .column {
      padding: 0px;
      height: 100%;
      width: 100%;
      padding: 0;

      ${breakpoint.small`
        padding: 30px 0;
        width: auto;
      `}
    }
    
	h1 { 
		margin: 2rem 1rem;
	  }

	p { 
		margin: 1rem;
		line-height: inherit;

		  ${breakpoint.small`
        width: 65%;
        line-height: 1.8em;
		  `}
		}
	}

	.deviceImage {
		margin: auto;
		width: 200px;
		padding-top: 0;
		margin-top: 0;
		
	  ${breakpoint.small`
			width: 250px;
			padding-top: 30px;
			margin-top: 30px;
	  `}
	  }

   ${ExternalButtonLink} {
	width: auto;
	margin-left: 1rem;
	margin-block: 0.5rem;
	}
  }

`

const Hero = () => {
  return (
    <StyledHero data-aos="fade-in">
      <div className="block--text">
        <StyledContainer>
          <div className="column">
            <h1>Why wait days<br />for STI results?</h1>
          <p>Affordable Rapid Testing offers streamlined, same-visit diagnosis and treatment - so you can have
          peace of mind for your sexual health</p>
          <ExternalButtonLink href="tel:+1-480-454-3630" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Call Now</ExternalButtonLink>
          </div>
          <div className="column">
            <StaticImage
              src="../../../assets/images/regional-pcr-testing/device-circle.png"
              width={280}
              layout="fixed"
              alt="Visby Medical Sexual Health Test"
              className="deviceImage"
            />
          </div>
        </StyledContainer>
      </div>
    </StyledHero>
  )
}

export default Hero
